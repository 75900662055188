import React, { useState, useEffect, useCallback, Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import { UserProvider, useUser } from "./contexts/UserContext";
import awsconfig from "./config/aws-exports";
import NavBar from "./components/NavBar";
import LandingPage from "./components/LandingPage";
import ErrorBoundary from "./contexts/ErrorBoundary";
import log from "./utils/logger";

// Lazy load components
const Dashboard = lazy(() => import("./components/Dashboard"));
const SignIn = lazy(() => import("./components/SignIn"));
const SignUp = lazy(() => import("./components/SignUp"));
const UserProfile = lazy(() => import("./components/UserProfile"));
const VerifyCode = lazy(() => import("./components/VerifyCode"));
const ForgotPassword = lazy(() => import("./components/ForgotPassword"));
const Events = lazy(() => import("./components/Events"));
const CreateEvent = lazy(() => import("./components/CreateEvent"));
const EventDetails = lazy(() => import("./components/EventDetails"));
const InvitationHandler = lazy(() => import("./components/InvitationHandler"));
const AttendeeForm = lazy(() => import("./components/AttendeeForm"));
const MatchesDashboard = lazy(() => import("./components/MatchesDashboard"));
const Feedback = lazy(() => import("./components/Feedback"));
const Demo = lazy(() => import("./components/Demo"));
const MatchPricingTiers = lazy(() => import("./components/MatchPricingTiers"));
const ConnectAccountSetup = lazy(() => import("./components/ConnectAccountSetup"));
const StripeConnectSuccess = lazy(() => import("./components/StripeConnectSuccess"));
const AttendeeEventPricing = lazy(() => import("./components/AttendeeEventPricing"));

if (process.env.NODE_ENV !== "production") {
  log.setLevel("debug");
}

Amplify.configure(awsconfig);

function AppContent() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { refreshUser } = useUser();

  const checkAuthState = useCallback(async () => {
    try {
      await Auth.currentAuthenticatedUser();
      setIsAuthenticated(true);
    } catch {
      setIsAuthenticated(false);
    }
  }, []);

  useEffect(() => {
    checkAuthState();
  }, [checkAuthState]);

  useEffect(() => {
    if (isAuthenticated) {
      refreshUser();
    }
  }, [isAuthenticated, refreshUser]);

  return (
    <div className="App min-h-screen bg-gray-100">
      <NavBar
        isAuthenticated={isAuthenticated}
        setIsAuthenticated={setIsAuthenticated}
      />

      <div className="container mx-auto px-4 py-8">
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route
              path="/"
              element={isAuthenticated ? <Dashboard /> : <LandingPage />}
            />

            <Route path="/dashboard" element={<Dashboard />} />
            <Route
              path="/signin"
              element={<SignIn setIsAuthenticated={setIsAuthenticated} />}
            />
            <Route
              path="/signup"
              element={<SignUp setIsAuthenticated={setIsAuthenticated} />}
            />
            <Route
              path="/verify-code"
              element={<VerifyCode setIsAuthenticated={setIsAuthenticated} />}
            />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/profile"
              element={<UserProfile setIsAuthenticated={setIsAuthenticated} />}
            />
            <Route path="/events" element={<Events />} />
            <Route path="/events/create" element={<CreateEvent />} />
            <Route path="/events/:eventId" element={<EventDetails />} />
            <Route path="/events/pricing-tiers" element={<MatchPricingTiers />} />
            <Route
              path="/:eventId/:invitationCode/register"
              element={<InvitationHandler />}
            />
            <Route
              path="/events/:eventId/register"
              element={<InvitationHandler />}
            />
            <Route path="/feedback" element={<Feedback />} />
            <Route
              path="/events/:eventId/attendees"
              element={<AttendeeForm />}
            />
            <Route
              path="/events/:eventId/matches"
              element={<MatchesDashboard />}
            />
            <Route path="/demo" element={<Demo />} />
            <Route path="/events/connect-setup" element={<ConnectAccountSetup />} />
            <Route path="/events/stripe-success" element={<StripeConnectSuccess />} />
            <Route path="/events/:eventId/pricing" element={<AttendeeEventPricing />} 
/>
          </Routes>
        </Suspense>
      </div>
    </div>
  );
}

function App() {
  useEffect(() => {
    const t0 = performance.now();

    return () => {
      const t1 = performance.now();
      console.log(`App took ${t1 - t0} milliseconds to render`);
    };
  }, []);

  return (
    <ErrorBoundary>
      <UserProvider>
        <AppContent />
      </UserProvider>
    </ErrorBoundary>
  );
}

export default App;
